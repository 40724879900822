.title_box {
    width: 40vw;
    margin: 11.87vw auto 0 auto;
}

.title_box img {
    width: 100%;
}

.welfare_box {
    width: 65vw;
    margin: 4.58vw auto 0 auto;
    display: flex;
    justify-content: center;
    gap: 1.4vw;
    flex-wrap: wrap;
}

.welfare {
    width: 9.6vw;
}

.welfare img {
    width: 100%;
}

.culture_box {
    width: 60vw;
    margin: 4.58vw auto 0 auto;
    display: flex;
    justify-content: center;
    gap: 3.125vw;
    flex-wrap: wrap;
}

.culture {
    width: 17vw;
}

.culture img {
    width: 100%;
}

.story_box {
    margin: 8.4vw auto 0 auto;
    width: 73.5vw;
    position: relative;
}

.storys {
    width: 61.875vw;
    margin: 0 auto;
}

.left_btn {
    position: absolute;
    cursor: pointer;
    width: 3.6vw;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.right_btn {
    position: absolute;
    cursor: pointer;
    width: 3.6vw;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

@media (max-width:820px) {
    .story_box {
        width: 90vw;
        display: flex;
        flex-wrap: wrap;
        gap: 2vw;
    }

    .story_box img{
        width: 44vw;
    }
}