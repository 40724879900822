.row {
    width: 65.4vw;
    margin: 11.98vw auto 0 auto;
}

.title_box {
    padding-left: 2vw;
}

.img_box {
    display: flex;
    position: relative;
    gap: 1vw;
    margin-top: 6.25vw;
    flex-direction: column;
}

.img_box div {
    height: 7.86vw;
    position: relative;
    transition: all 0.5s;
}

.img_box div img {
    position: absolute;
    bottom: 0;
    height: 100%;

}



.img_box div:hover {
    height: 13.6vw;
}

.logo {
    position: absolute;
    width: 18.75vw;
    top: -4.88vw;
    right: 0;
}

@media (max-width:820px) {
    .row{
        width: 82vw;
    }

    .img_box div {
        height: 16.26vw;
    }
}