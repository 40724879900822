.title_box {
    margin-top: 11.3vw;
}

.btn_box {
    width: 9.58vw;
    margin: 9px auto 0 auto;
}

.time_box {
    width: 100%;
    margin-top: 1.93vw;
}

.btn_box img,
.time_box img {
    width: 100%;
}


.development_box {
    width: 61vw;
    margin: 4.17vw auto 10.94vw auto;
    display: flex;
    gap: 3.9vw;
}

.development {
    flex: 1;
}

.development:hover .title {
    color: #FE6900;
    border-bottom: 2px solid #FE6900;
}

.development:hover .content {
    opacity: 1;
    color: #FE6900;
}

.title {
    font-size: 1.35vw;
    font-weight: 600;
    color: #140E0A;
    padding-bottom: 0.91vw;
    border-bottom: 2px solid rgba(20, 14, 10, 0.4);
}

.content {
    margin-top: 1.67vw;
    white-space: pre-wrap;
    opacity: 0.8;
    font-size: 0.73vw;
    font-weight: 500;
    line-height: 1.3vw;
    color: rgba(20, 14, 10, 0.51);
}

@media (max-width:820px) {
    .development_box{
        width: 86vw;
    }

    .content{
        font-size: 1.33vw;
        line-height: 1.87vw;
    }
}