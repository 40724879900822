.title_box {
    margin: 10.42vw auto 0 auto;
    width: 70vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title_box2 {
    padding-top: 11.15vw;
}

.row {
    width: 70vw;
    margin: 10px auto 0 auto;
    position: relative;
}

.row img {
    width: 26.67vw;
}

.img2 {
    position: absolute;
    bottom: -3.85vw;
    left: 50%;
    margin-left: -12.83vw;
}

.img3 {
    position: absolute;
    right: 0;
}

.logo {
    width: 20.57vw;

}

.logo img {
    width: 100%;
}

.img_box {
    margin-top: 8vw;
    text-align: center;
}


.app {
    width: 67.3vw;
    margin: 0 auto;
}