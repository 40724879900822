.container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.title {
    width: 100%;
    margin-top: 11.3vw;
}

.teacher {
    width: 74%;
    margin-top: 8vw;
}

.teachers_box {
    width: 100%;
}

.korean_teachers_box1 {
    margin-top: 4vw;
    width: 100%;
    height: 20.4vw;
    overflow: hidden;
    /* 确保图片不会移出容器外 */
    white-space: nowrap;
    /* 防止换行 */
    position: relative;
    /* 相对于容器定位 */
}

.korean_teachers_box1 img {
    height: 100%;
    position: absolute;
    /* 绝对定位 */
    top: 50%;
    /* 垂直居中 */
    transform: translateY(-50%);
    /* 垂直居中调整 */
    animation: slide1 50s linear infinite;
    /* 应用动画 */
}

.korean_teachers_box1:hover img {
    animation-play-state: paused;
}

@keyframes slide1 {
    0% {
        left: 0px;
        /* 从容器右边开始 */
    }

    100% {
        left: -280vw;
        /* 移动到容器左边外 */
    }
}


.korean_teachers_box2 {
    margin-top: 4vw;
    width: 100%;
    height: 20.4vw;
    overflow: hidden;
    /* 确保图片不会移出容器外 */
    white-space: nowrap;
    /* 防止换行 */
    position: relative;
    /* 相对于容器定位 */
}

.korean_teachers_box2 img {
    height: 100%;
    position: absolute;
    /* 绝对定位 */
    top: 50%;
    /* 垂直居中 */
    transform: translateY(-50%);
    /* 垂直居中调整 */
    animation: slide2 50s linear infinite;
    /* 应用动画 */
}

.korean_teachers_box2:hover img {
    animation-play-state: paused;
}

@keyframes slide2 {
    0% {
        left: -290vw;
    }

    100% {
        left: 0px;
    }
}




.japan_teachers_box1 {
    margin-top: 4vw;
    width: 100%;
    height: 20.4vw;
    overflow: hidden;
    /* 确保图片不会移出容器外 */
    white-space: nowrap;
    /* 防止换行 */
    position: relative;
    /* 相对于容器定位 */
}

.japan_teachers_box1 img {
    height: 100%;
    position: absolute;
    /* 绝对定位 */
    top: 50%;
    /* 垂直居中 */
    transform: translateY(-50%);
    /* 垂直居中调整 */
    animation: slide3 10s linear infinite;
    /* 应用动画 */
}

.japan_teachers_box1:hover img {
    animation-play-state: paused;
}

@keyframes slide3 {
    0% {
        left: 0px;
        /* 从容器右边开始 */
    }

    100% {
        left: -23vw;
        /* 移动到容器左边外 */
    }
}


.japan_teachers_box2 {
    margin-top: 4vw;
    width: 100%;
    height: 20.4vw;
    overflow: hidden;
    /* 确保图片不会移出容器外 */
    white-space: nowrap;
    /* 防止换行 */
    position: relative;
    /* 相对于容器定位 */
}

.japan_teachers_box2 img {
    height: 100%;
    position: absolute;
    /* 绝对定位 */
    top: 50%;
    /* 垂直居中 */
    transform: translateY(-50%);
    /* 垂直居中调整 */
    animation: slide4 20s linear infinite;
    /* 应用动画 */
}

.japan_teachers_box2:hover img {
    animation-play-state: paused;
}

@keyframes slide4 {
    0% {
        left: -88vw;
    }

    100% {
        left: 0px;
    }
}