.carousel_box {
    width: 100%;
}

.single_carousel {
    width: 100%;
    outline: none;
    box-shadow: none;
    position: relative;
}

.single_carousel img {
    width: 100%;
    outline: none;
    box-shadow: none;
}

.banner_btn {
    position: absolute;
    width: 14% !important;
    bottom: 7vw;
    left: 10vw;
    cursor: pointer;
    z-index: 999;
}

.title_box {
    margin-top: 10.42vw;
}

.regions_box {
    display: flex;
    flex-wrap: wrap;
    width: 85vw;
    margin: 4.1vw auto 0 auto;
    gap: 0.5vw;
    row-gap: 1vw;
}

.single_resion {
    width: calc((100% - 2vw) / 5);
}

.single_resion img {
    width: 100%;
}

.services_box {
    display: flex;
    flex-wrap: wrap;
    width: 75vw;
    margin: 4.1vw auto 0 auto;
    gap: 1.3vw;
    row-gap: 1vw;
}

.single_service {
    width: calc((100% - 2.6vw) / 3);
}

.single_service img {
    width: 100%;
}

.reason_box {
    width: 70vw;
    margin: 5.2vw auto 0 auto;
}

.reason_box img {
    width: 100%;
}

.voice_box {
    margin-top: 10vw;
    width: 100%;
    background-size: 100% auto;
    background-position: 0 9vw;
    background-repeat: no-repeat;
}

.voice_top_box {
    display: flex;
    height: 31.25vw;
}

.voice_top_left_box {
    width: 23%;
    margin-left: 12%;
}

.voice_top_left_box img {
    width: 100%;
}

.voice_top_right_box {
    width: 65%;
    height: 26.75vw;
    overflow: hidden;
    /* 确保图片不会移出容器外 */
    white-space: nowrap;
    /* 防止换行 */
    position: relative;
    /* 相对于容器定位 */
}

.voice_top_right_box img {
    height: 100%;
    position: absolute;
    /* 绝对定位 */
    top: 50%;
    /* 垂直居中 */
    transform: translateY(-50%);
    /* 垂直居中调整 */
    animation: slide 90s linear infinite;
    /* 应用动画 */
}

.voice_top_right_box:hover img {
    animation-play-state: paused;
}

@keyframes slide {
    0% {
        left: 300px;
        /* 从容器右边开始 */
    }

    100% {
        left: -4000px;
        /* 移动到容器左边外 */
    }
}

.img_box {
    width: 80%;
    margin: 0 auto;
}

.img_box img {
    width: 100%;
    cursor: pointer;
}