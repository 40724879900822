.title_box {
    margin-top: 10.42vw;
}

.img_box {
    display: flex;
    gap: 5px;
    width: 65vw;
    margin: 8.85vw auto 0 auto;
}

.single_img {
    flex: 1
}

.single_img:hover {
    margin-top: -20px;
    transition: all 0.5s;
}

.single_img img {
    width: 100%;
}

@media (max-width:820px) {
    .img_box {
        justify-content: center;
    }
}