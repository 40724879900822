.container {
    display: flex;
    width: 61.67vw;
    margin: 0 auto 0 auto;
    padding-top: 7.8vw;
}

.img_box {
    width: 26.51vw;
}

.content_box {
    flex: 1.33;
    padding-left: 2vw;
}

.img_box img {
    width: 100%;
}

.content {
    margin-top: 1.67vw;
    font-size: 0.94vw;
    font-weight: 500;
    line-height: 1.56vw;
    color: rgba(20, 14, 10, 0.51);
}

@media (max-width:820px) {
    .container {
        width: 90vw;
    }

    .img_box{
        width: 38.87vw;
    }

    .content{
        font-size: 1.6vw;
        line-height: 2.2vw;
    }
}