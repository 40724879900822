.title_box {
    padding-top: 11.15vw;
    padding-left: 19vw;
}

.img_box {
    margin-top: 7.75vw;
    height: 38.59vw;
    width: 79vw;
    position: relative;
}

.img_box .card {
    width: 23.75vw;
    height: 12.86vw;
    position: absolute;
}

.img_box .card:hover {
    margin-top: -10px;
    transition: all 0.5s;
}

.img_box .bg {
    width: 100%;
    height: 100%;
}



@media (max-width:820px) {
    .title_box {
        padding-left: 17.4vw;
    }

    .img_box {
        width: 89vw;
        height: 59.6vw;
        margin-top: 15vw;
    }

    .img_box .card {
        width: 47.47vw;
        height: 27.07vw;
    }

    .img_box .card:nth-of-type(3) {
        width: 45.47vw;
        height: 26.07vw;
    }

    .img_box .card:nth-of-type(5) {
        width: 46.47vw;
        height: 27.07vw;
    }
}