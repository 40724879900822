.backgroundImage {
    margin-top: 5.2vw;
    position: relative;
    width: 25vw;
    height: 5.2vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 1s;
    left: 50%;
    margin-left: -12.5vw;
}

.arrowImage {
    position: absolute;
    left: 0;
    height: 5.2vw;
    width: 5.2vw;
    transition: all 1s;
}

.text {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 1s;
    width: calc(100% - 5.2vw);
    text-align: center;
    font-size: 1.66vw;
    font-weight: 600;
    color: #140E0A;
}