.title_box {
    margin-top: 11.3vw;
}

.courses {
    display: flex;
    width: 60vw;
    margin: 4.17vw auto 0 auto;
    justify-content: space-between;
    gap: 1.35vw;
}

.left_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.83vw;
    width: 22.18vw;
}



.right_box {
    width: 35.31vw;
}

.right_box img {
    width: 100%;
}

@media (max-width:820px) {
    .courses {
        width: 92vw;
        gap: 0;
    }

    .left_box{
        width: 32vw;
        gap: 0;
    }

    .right_box{
        width: 60.27vw;
    }
}