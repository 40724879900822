.App_header {
    width: 100vw;
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.header_box {
    width: 80vw;
    margin: 0 auto;
    padding: 0;
    max-width: 1540px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    height: 90px;
}

.head_title_active {
    opacity: 1 !important;
    border-bottom: 4px solid #fff;
    border-radius: 4px;
}

.title_box {
    display: flex;
    gap: 4vw;
}

.title_box>div {
    color: #fff;
    opacity: 0.5;
    user-select: none;
    cursor: pointer;
    padding-bottom: 10px;
}

#logo {
    height: 36px;
}

@media (max-width:820px) {
    .header_box {
        width: 84vw;
    }

    .header {
        height: 11vw;
    }

    .menu {
        height: 5.8vw;
        width: 6.8vw;
    }
}