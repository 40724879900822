.title_box {
    margin-top: 11.3vw;
}

.img_box {
    text-align: center;
    margin-top: 6.25vw;
}




.app {
    width: 67.3vw;
    margin: 0 auto;
}

@media (max-width:820px) {
    .app{
        width: 80.3vw;
    }
}