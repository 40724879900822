.service{
    height: 16.3vw;
    width: 100%;
    background-size: auto 100%;
    transition: all 5s linear;
    margin-top: 3.64vw;
}

.service:first-of-type{
    margin-top: 92px;
}