.content {
    border-radius: 1.56vw;
    display: flex;
    gap: 0.78vw;
    flex-direction: column;
    justify-content: center;
    padding: 1.88vw 2.73vw;
}

.title {
    font-size: 1.25vw;
    font-weight: 600;
}

.subtitle {
    font-size: 1.04vw;
    font-weight: 600;
}


@media (max-width:820px) {
    .title {
        font-size: 2.13vw;
    }
    
    .subtitle {
        font-size: 1.87vw;
    }

}