.container {
    width: 92%;
    margin: 0 auto;
}

.title_box {
    margin-top: 11.3vw;
}

.img_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left_box {
    width: 50vw;
    position: relative;
}

.left_btn {
    position: absolute;
    top: 50%;
    width: 4vw;
    margin-top: -2vw;
    z-index: 99;
}

.right_btn {
    position: absolute;
    top: 50%;
    width: 4vw;
    right: 0;
    margin-top: -2vw;
    z-index: 99;
}

.right_box {
    width: 33vw;
}

.right_box img {
    width: 100%;
    user-select: none;
}




@media (max-width:820px) {}