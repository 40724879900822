.box {
    margin-top: 16.56vw;
}

.img_box {
    width: 72.07vw;
    margin: 1.61vw auto 0 auto;
}

.img_box img{
    width: 100%;
}


@media (max-width:820px) {
    .img_box {
        width: 90vw;
    }
}