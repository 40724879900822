* {
    margin: 0;
    padding: 0;
}

.App {}

.bg {
    width: 100vw;
    /* 调整为你的遮罩宽度 */
    height: 100vh;
    /* 调整为你的遮罩高度 */
    /* background-image: url('./assets/index/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; */
    background: #FF6900;
    position: relative;
    display: flex;
    flex-direction: column;
}

.index-title {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, 0);
}

.guide-logo {
    width: 15vw;
    transition: all 0.5s;
    cursor: pointer;
    position: absolute;
    bottom: 0;
}



.guide-logo img {
    width: 100%;
    position: absolute;
    bottom: 18.88vh;
    transition: all 0.5s;
}

.guide-logo img:hover {
    bottom: 22.31vh;
}

.more-btn {
    position: absolute;
    width: 200px;
    transition: all 0.5s;
    bottom: 2vw;
    right: 4vw;
    cursor: pointer;
}

.touch_logo {
    margin-left: 25%;
    text-align: center;
    width: 50% !important;
    bottom: 0 !important;
}

.left-bottom-logo {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 18.49vw;
}

.more-btn:hover {
    right: 2vw;
}

.guide-title {
    position: absolute;
    top: 15vh;
    width: 100%;
    text-align: center;
    font-size: 42px;
    font-weight: 600;
    color: #314E7E;
    line-height: 69px;
}

.guide-subtitle {
    position: absolute;
    top: 24vh;
    width: 100%;
    text-align: center;
    margin-top: 17px;
    font-size: 24px;
    font-weight: 500;
    color: #354F79;
    line-height: 32px;
    letter-spacing: 5px;
}

.guide-box {
    display: flex;
    width: 80vw;
    position: absolute;
    justify-content: space-between;
    bottom: 0;
    left: 17.19vw;
}

#logo {
    height: 28px;
    cursor: pointer;
}

.mask {
    position: fixed;
    z-index: 99;
    width: 100vw;
    /* 调整为你的遮罩宽度 */
    height: 100vh;
    /* 调整为你的遮罩高度 */
    overflow: hidden;
    /* 隐藏超出部分 */
    background-color: rgba(4, 3, 2, 0.5);
    /* 设置背景颜色为半透明的白色 */
}

.mask .modal {
    width: 20vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 居中显示图片 */
}

.mask .bigModal {
    width: 63.6vw;
    height: 33.85vw;
    position: relative;
}

.mask .modal_close {
    position: absolute;
    width: 2vw;
    cursor: pointer;
    right: 38px;
    top: 38px;
}

.mask .big_modal_close {
    top: 85%;
}

.carousel_box {
    height: 44.27vw;
    position: relative;
}

.ant-carousel {
    height: 100%;
}

.ant-carousel .slick-slider {
    height: 100%;
}

.ant-carousel .slick-track {
    height: 100%;
}

.slick-list {
    height: 100%;
}

.slick-slide div {
    height: 100%;
}

.carousel {
    height: 100%;
}

.carousel img {
    width: 100%;
    height: 100%;
}

.ant-carousel .slick-dots li.slick-active {
    width: 10px;
}

.ant-carousel .slick-dots li {
    width: 10px;
    height: 10px;
}

.ant-carousel .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.qrcode {
    user-select: none;
    position: fixed;
    width: 12.5vw;
    top: 50%;
    right: 0;
    z-index: 999;
}

.carousel_line {
    width: 18.7vw;
    position: absolute;
    bottom: -22%;
    left: 9.8vw;
}

.worker_title {
    margin-top: 8.33vw;
}

.workers {
    margin-top: 5.47vw;
    background-size: auto 100%;
    transition: all 3s linear;
    height: 12.5vw;
}

.swiper-slide img {
    width: 100%;
}

.bgf6f7f9 {
    background-color: #f6f7f9;
}

.flex {
    display: flex;
}

.flex p:first-child {
    font-weight: bold;
    white-space: nowrap;
    color: #140E0A;
}

@media (max-width:820px) {
    .index-title {
        top: 7%;
    }

    .guide-logo img {
        width: 37.33vw;
    }

    .workers {
        height: 19.5vw;
    }

    .more-btn {
        right: 10%;
        bottom: 1vh;
        width: 25vw;
    }

    .left-bottom-logo {
        display: none;
    }

    .guide-box {
        flex-direction: row;
        width: 90vw;
        row-gap: 20px;
        margin: 0 auto 0 5%;
        flex-wrap: wrap;
    }

    .guide-logo {
        width: 100%;
    }

    .guide-title {
        font-size: 18px;
        line-height: 26px;
        width: 90%;
        margin: 0 auto;
        top: 25vw;
        text-align: left;
    }

    .guide-subtitle {
        font-size: 10px;
        line-height: 10px;
        top: 41vw;
        margin: 0 auto;
        text-align: left;
        width: 90%;
    }


    .guide-title,
    .guide-subtitle {
        left: 5vw;
    }


    .guide-box {
        left: 0;
        bottom: 8vh;
    }

    .guide-logo {
        position: unset;
        width: 40vw;
    }

    .guide-logo img {
        position: unset;
        width: 100%;
    }

    .mask .modal {
        width: 80vw;
    }

    .mask .bigModal {
        width: 69.3vw;
        height: 88.5vw;
    }

    .mask .modal_close {
        width: 20px;
        right: 15px;
        top: 15px;
    }
}