.row {
    width: 83.39vw;
    height: 23.85vw;
    margin: 10.9vw auto 0 auto;
    display: flex;
}

.title_box {
    margin-top: 7vw;
    width: 25vw;
}

.img_box {
    display: flex;
    gap: 1.2vw;
}

.img_box div {
    width: 12.08vw;
    position: relative;
    transition: all 0.5s;
}

.img_box img {
    position: absolute;
    bottom: 0;
    width: 100%;

}

.img_box div:hover {
    width: 16.98vw;
}

@media (max-width:820px) {
    .row {
        width: 90vw;

    }

    .title_box {
        padding-left: 0vw;
    }

    .img_box div {
        width: 13.6vw;
    }
}