.title_box {
    margin-top: 11.15vw;
}

.img_box {
    margin-top: 6.25vw;
    margin-bottom: 11vw;
    height: 12.5vw;
}

@media (max-width:820px) {}