.title_box {
    margin-top: 11.3vw;
}

.img_box {
    position: relative;
    text-align: center;
}

.line {
    position: absolute;
    left: -4.4vw;
    top: 0;
    width: 17.19vw;
}


.app {
    width: 67.3vw;
    margin: 0 auto;
}

@media (max-width:820px) {
    .app{
        width: 80.3vw;
    }
}