.title {
    font-size: 3.23vw;
    font-weight: 600;
    line-height: 3.85vw;
    letter-spacing: 2.38px;
    color: #FE6900;
}

.subtitle {
    font-size: 1.67vw;
    font-weight: 600;
    line-height: 1.15vw;
    letter-spacing: 0px;
    color: rgba(20, 14, 10, 0.51);
}


@media (max-width:820px) {
    .title {
        font-size: 3.73vw;
        line-height: 4.93vw;
    }
    
    .subtitle {
        font-size: 2.4vw;
        line-height: 3.2vw;
    }

}