.App_footer {
    position: relative;
    width: 100%;
    margin-top: 11.46vw;
}

.foot_bg {
    width: 100%;
    vertical-align: bottom;
}

.beian {
    cursor: pointer;
    position: absolute;
    width: 200px;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
}

@media (max-width:820px) {
    .beian {
        width: 100px;
        bottom: 5px;
    }
}