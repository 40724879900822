.container {
    
    width: 61.67vw;
    gap: 2.6vw;
    margin: 0 auto 0 auto;
    padding-top: 7.8vw;
}

.title {
    margin-top: 7.8vw;
    width: 100%;
}

.title img {
    width: 100%;
}



@media (max-width:820px) {
    .container {
        width: 90vw;
        padding-top: 1vw;
    }

}