.box {
    margin-top: 2.6vw;
    user-select: none;
    display: flex;
    gap: 3.6vw;
}

.imgbox {
    flex: 1;
}

.imgbox img {
    width: 100%;
}

.contentbox {
    flex: 1.61;
    padding-top: 15px;
}

.timeBox {
    padding-bottom: 10px;
    border-bottom: 2px solid #FE6900;
}

.box:hover .content {
    color: #FE6900;
}

.time {
    font-size: 30px;
    font-weight: 600;
    color: #140E0A;
    line-height: 39px;
}

.month {
    font-size: 16px;
    font-weight: 600;
    color: #140E0A;
    line-height: 18px;
    opacity: 0.5;
}

.content {
    font-size: 16px;
    font-weight: 500;
    color: rgba(20, 14, 10, 0.7);
    line-height: 23px;
    max-height: 11vw;
    overflow-y: auto;
}

@media (max-width:820px) {
    .box {
        margin-top: 3px;
        flex-direction: column;
        gap: 0;
    }

    .contentbox {
        padding-top: 0;
        width: 80%;
        margin: 10px auto 25px;
    }

    .timeBox {
        padding-bottom: 3px;
    }

    .time {
        font-size: 14px;
        font-weight: 600;
        color: #140E0A;
        
        line-height: 19px;
    }

    .month {
        font-size: 14px;
        font-weight: 600;
        color: #140E0A;
        opacity: 1;
        line-height: 19px;
    }

    .content {
        font-size: 9px;
        font-weight: 500;
        color: rgba(20, 14, 10, 0.7);
        line-height: 13px;
    }
}