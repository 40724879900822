.title_box {
    margin-top: 9.38vw;
}

.row {
    margin: 12.42vw auto 0 auto;
    width: 73vw;
    display: flex;
}


.img_box {
    flex: 1.07;
}

.img_box img {
    width: 100%;
}

.content_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    border-bottom: 4px solid #FE6900;
    padding-bottom: 1.04vw;
    display: flex;
    width: 22vw;
    justify-content: space-between;
}

.left_title {
    color: #FE6900;
    font-size: 3.65vw;
    font-weight: 600;
}

.right_title {
    width: 13.1vw;
    padding-left: 1.56vw;
    position: relative;
    font-size: 1.46vw;
    font-weight: 600;
    color: rgba(254, 105, 0, 0.6);
}

.right_title p {
    position: absolute;
    bottom: 0;
}

.content {
    font-size: 1.35vw;
    font-weight: 500;
    margin-top: 2vw;
    color: rgba(20, 14, 10, 0.51);
}

@media (max-width:820px) {
    .row{
        width: 90vw;
    }

    .title{
        width: 38.66vw;
    }

    .right_title{
        width: 26.4vw;
    }

    .content{
        font-size: 1.87vw;
        line-height: 2.53vw;
    }
}