.title_box {
    padding-top: 11.15vw;
}

.img_box {
    margin: 7.75vw auto 0 auto;
    display: flex;
    width: 85vw;
    justify-content: space-between;
}

.single_img {
    transition: all 0.3s;
    flex: 1;
    position: relative;
}

.single_img:hover {
    margin-top: -30px;
}

.single_img:hover .btn {
    bottom: calc(6% + 30px);
}

.course {
    width: 100%;
}

.btn {
    cursor: pointer;
    width: 62%;
    height: 13%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 6%;
    transition: all 0.3s;
    left: 50%;
    transform: translate(-50%, 0);
    background-image: url('../../../../assets/course_product/button/primary.png');
}

.btn:hover {
    background-image: url('../../../../assets/course_product/button/disable.png');
}

@media (max-width:820px) {
    .img_box {
        flex-wrap: wrap;
    }

    .single_img {
        flex: none;
        width: calc(50% - 10px);
    }

    .single_img:hover {
        margin-top: 0;
    }

    .single_img:hover .btn {
        bottom: 6%;
    }

    .btn:hover {
        background-image: url('../../../../assets/course_product/button/primary.png');
    }
}