.title_box {
    margin-top: 11.15vw;
}

.row {
    display: flex;
}

.img_box {
    width: 56.46vw;
}

.img_box img {
    width: 100%;
}

.content_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-size: 2.92vw;
    font-weight: bolder;
    color: #FE6900;
}

@media (max-width:820px) {
    .title {
        font-size: 4vw;
        line-height: 5.2vw;
    }

    .content {
        font-size: 1.6vw;
        line-height: 2.13vw;
    }

    .img_box {
        width: 90vw;
    }
}

.content {
    margin-top: 2.6vw;
    font-size: 1.15vw;
    font-weight: bold;
    text-align: justify;
    color: rgba(20, 14, 10, 0.51);
}

.teacher_box {
    margin-top: 8.33vw;
}

@media (max-width:820px) {
    .title {
        font-size: 4vw;
        line-height: 5.2vw;
    }

    .content {
        font-size: 1.6vw;
        line-height: 2.13vw;
    }
}