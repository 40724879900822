.box {
    margin-top: 10.89vw;
    background-image: url('../../../../assets/home/7/bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 15.05vw;
}

.title_box {
    margin-top: 11.45vw;
}

.event_box {
    width: 64vw;
    margin: 2.6vw auto 0;
    display: flex;
    gap: 1.98vw;
    flex-direction: column;
    justify-content: space-between;
}

.event_box img {
    width: 100%;
    cursor: pointer;
}

.btn {
    width: 9.58vw !important;
    margin: 0 auto;
}

.cert{
    height: 18.18vw;
    width: 100%;
    background-size: auto 100%;
    transition: all 5s linear;
}

.cert:first-of-type{
    margin-top: 92px;
}

@media (max-width:820px) {
    .event_box{
        width: 92vw;
    }
}