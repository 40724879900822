.title_box {
    margin-top: 11.15vw;
}

.row {
    display: flex;
    justify-content: center;
}

.img_box {
    /* width: 56.46vw; */
    width: 47.46vw;
}

.img_box img {
    width: 100%;
}

.content_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-size: 2.92vw;
    font-weight: bolder;
    color: #FE6900;
}

.content {
    margin-top: 2.6vw;
    font-size: 1.15vw;
    font-weight: 500;
    text-align: justify;
    color: rgba(20, 14, 10, 0.51);
}
