.title_box {
    margin-top: 7vw;
}

.img_box {
    display: flex;
    width: 70vw;
    margin: 6.25vw auto 0 auto;
    gap: 1.2vw;
}

.img_box div {
    flex: 1;
}

.img_box div video,.img_box div img {
    width: 100%;
    height: 100%;
}

/* 隐藏video 所有控件 */
/* .video::-webkit-media-controls-enclosure {
    display: none;
} */

@media (max-width:820px) {}