.title_box {
    margin-top: 10.42vw;
}

.img_box {
    display: flex;
    gap: 2.24vw;
    width: 55vw;
    margin: 8.85vw auto 0 auto;
}

.single_img {
    position: relative;
    flex: 1
}

.single_img img {
    width: 100%;
}

.btn{
    position: absolute;
    bottom: 1vw;
    left: 0;
    width: 60%!important;
    cursor: pointer;
    left: 20%;
}

@media (max-width:820px) {
    .img_box {
        justify-content: center;
    }
}